<template>
	<div class="container">
		<div class="shop">
      <Filters />
      <FiltersMobile />
      <div class="shop__catalog">      
        <Banner />
        <Catalog />
        <Banner2 />
        <Catalog />
        <BannerBottom />
      </div>
		</div>
	</div>
</template>

<script>
  import Filters from '@/components/Filter.vue'
  import FiltersMobile from '@/components/FilterMobile.vue'
  import Banner from '@/components/Banner.vue'
  import Catalog from '@/components/Catalog.vue'
  import Banner2 from '@/components/Banner2.vue'
  import BannerBottom from '@/components/BannerBottom.vue'
  export default {
    name: "Shop",
    components: {
      Filters,
      Banner,
      Catalog,
      Banner2,
      BannerBottom,
      FiltersMobile,
    }
  }
  
</script>

<style lang="sass">
@import "../sass/functions.sass"
.shop
  display: flex
  margin-top: vw(47px)
  @media screen and (max-width: 760px) 
    display: block
    margin-top: vwm(17px)
  &__catalog
    margin-left: vw(21px)
    @media screen and (max-width: 760px)
      margin-left: 0
      margin-top: vwm(24px)


</style>