<template>
	<div class="filter-mobile">
		<div class="filter-mobile__btn" @click="showModal = true">Фильтры 
			<a href="#">
				<img src="../assets/img/Filters.svg" alt="">
				<div class="filter-mobile__status"></div>
			</a>
		</div>
		<div class="filter-mobile__status"></div>

		<div class="filter-mobile__modal" v-if="showModal == true">
			<div class="filter-mobile__modal-top">
				<div class="filter-mobile__modal-close" @click="showModal = false"></div>
				<div class="filter-mobile__modal-title">Фильтры</div>
			</div>
			<a class="filter-mobile__modal-btn">Применить</a>
		</div>
	</div>
</template>

<script>
	import noUiSlider from 'nouislider';
	import 'nouislider/dist/nouislider.css';
	export default {
		name: "FiltersMobile",
		data() {
			return {
				slider: null,
				rangeStart: 4000000,
				rangeEnd: 8000000,
				showModal: false,
			}
		},
		mounted() {
			this.slider = noUiSlider.create(this.$refs.slider, {
				start: [this.rangeStart, this.rangeEnd],
				connect: true,
				range: {
					'min': Math.round(this.rangeStart),
					'max': Math.round(this.rangeEnd)
				}
			});
			this.slider.on('update', (values) => {
				this.rangeStart = Math.round(values[0]);
				this.rangeEnd = Math.round(values[1]);
			});
		}
	}
</script>

<style lang="sass">
@import "../sass/functions.sass"
.filter-mobile
	display: none
	@media screen and (max-width: 760px)
		display: block
	&__btn
		cursor: pointer
		color: #1C1F22
		font-family: SB Sans Text
		font-size: vwm(14px)
		font-weight: 400
		line-height: vwm(20px)
		letter-spacing: vwm(-0.28px)
		display: flex
		align-items: flex-start
		width: calc( 100% + vwm(8px))
		margin-left: vwm(-4px)
		a
			position: relative
			margin-left: vwm(9px)
			cursor: pointer
			.filter-mobile__status
				position: absolute
				width: vwm(5px)
				height: vwm(5px)
				background: #8654CC
				border-radius: 50%
				top: 0
				right: vwm(-5px)
	&__modal
		display: none
		@media screen and (max-width: 760px)
			display: block
			top: 0
			left: 0
			position: fixed
			z-index: 200
			width: 100%
			height: 100%
			background-color: #FFF
		&-top
			display: flex
			align-items: center
			justify-content: center
			position: relative
			padding: vwm(8px) vwm(14px)
			border-bottom: 1px solid rgba(212, 218, 223, 0.5)
		&-title
			display: flex
			justify-content: center
			color: #1C1F22
			text-align: center
			font-family: SB Sans Display
			font-size: vwm(18px)
			font-style: normal
			font-weight: 750
			line-height: vwm(24px)
		&-close
			cursor: pointer
			position: absolute
			left: vwm(12px)
			background-size: contain
			background-repeat: no-repeat
			background-image: url(~@/assets/img/Cross.svg)
			width: vwm(24px)
			height: vwm(24px)
		&-btn
			cursor: pointer
			display: flex
			width: vwm(343px)
			height: vwm(36px)
			padding: vwm(8px) vwm(16px)
			justify-content: center
			align-items: center
			gap: vwm(4px)
			border-radius: vwm(1000px)
			background: #FC4C02
			margin: auto
			margin-top: vwm(29px)
			color: #FFF
			font-family: SB Sans Text
			font-size: vwm(14px)
			line-height: vwm(20px)
			letter-spacing: vwm(-0.28px)
</style>