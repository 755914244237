<template>
	<div class="hero">
		<div class="container">
			<div class="swiper">
				<div class="swiper-wrapper">
					<div class="swiper-slide">
						<div class="hero__content">
							<div class="hero__text">
								<div class="hero__title"><img src="../assets/img/PIK.svg" alt=""></div>
								<div class="hero__subtitle">Квартиры<br>для жизни</div>
								<div class="hero__description">Удобная квартира давно перестала<br>быть роскошью – теперь это норма<br>для любой семьи</div>
							</div>
							<div class="hero__img"></div>
							<div class="hero__call" @click="showModal = true"></div>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="hero__content">
							<div class="hero__text">
								<div class="hero__title"><img src="../assets/img/PIK.svg" alt=""></div>
								<div class="hero__subtitle">Квартиры<br>для жизни</div>
								<div class="hero__description">Удобная квартира давно перестала<br>быть роскошью – теперь это норма<br>для любой семьи</div>
							</div>
							<div class="hero__img"></div>
							<div class="hero__call" @click="showModal = true"></div>
						</div>
					</div>
				</div>
				<div class="swiper-pagination"></div>
			</div>
		</div>
		<div class="hero__modal" @click="modalClick" v-if="showModal == true">
			<div class="hero__iframe-wrapper">
				<iframe src="https://www.pik.ru/callback?source=megamarket" class="hero__iframe" frameborder="0"></iframe>
				<div class="hero__close" @click="showModal = false"></div>
			</div>
		</div>
	</div>
</template>

<script>
  import Swiper from 'swiper';
  import { Pagination } from 'swiper/modules';
  import 'swiper/swiper-bundle.min.css';
  import 'swiper/swiper.css';

  export default {
	name: "Hero",
	data() {
		return {
			showModal: false,
			swiper: null,
		}
	},
	mounted() {
		this.swiper = new Swiper('.swiper', {
			loop: true,
			modules: [Pagination],
			pagination: {
				el: '.swiper-pagination',
				
			},
		});
	},
	methods: {
		modalClick(event) {
		if (event.target.classList.contains('hero__modal') && this.showModal === true) {
			this.showModal = false;
		}}
	},
  }
  
</script>

<style lang="sass">
@import "../sass/functions.sass"

.hero
	background-color: #fff
	&__modal
		position: fixed
		top: 0
		left: 0
		z-index: 200
		width: 100%
		height: 100%
		background: rgba(0, 0, 0, 0.40)
		display: flex
		align-items: center
		justify-content: center
	&__iframe
		&-wrapper
			width: 521px
			height: 600px
			position: relative
			@media screen and (max-width: 760px)
				width: 343px
				height: 663px
			iframe
				width: 100%
				height: 100%
	&__close
		cursor: pointer
		position: absolute
		top: vw(20px)
		right: vw(20px)
		background-size: contain
		background-repeat: no-repeat
		background-image: url(~@/assets/img/close1.svg)
		width: vw(16px)
		height: vw(16px)
		@media screen and (max-width: 760px)
			width: vwm(12px)
			height: vwm(12px)
			top: vwm(20px)
			right: vwm(20px)
	&__content
		border-radius: vw(24px)
		background: #FAFAFA
		display: flex
		position: relative
		@media screen and (max-width: 760px)
			border-radius: vwm(16px)
	&__text
		padding-top: vw(66px)
		padding-left: vw(105px)
		@media screen and (max-width: 760px)
			padding-top: vwm(5px)
			padding-left: vwm(25px)
	&__title
		margin-left: vw(6px)
		img
			width: vw(172px)
			height: vw(58px)
			@media screen and (max-width: 760px)
				width: vwm(42px)
				height: vwm(32px)
	&__subtitle
		color: #262423
		font-family: "Graphik LC"
		font-size: vw(70px)
		font-style: normal
		font-weight: 600
		line-height: vw(77px)
		margin-top: vw(39px)
		@media screen and (max-width: 760px)
			margin-top: vwm(-1px)
			font-size: vwm(17px)
			line-height: vwm(18px)
	&__description
		color: #262423
		font-family: "Graphik LC"
		font-size: vw(28px)
		font-style: normal
		font-weight: 300
		line-height:  vw(30px)
		margin-top: vw(31px)
		@media screen and (max-width: 760px)
			font-size: vwm(7px)
			line-height: vwm(8px)
			letter-spacing: vwm(-0.2px)
	&__img
		display: flex
		border-radius: vw(45.254px) vw(24px) vw(24px) vw(45.254px)
		background-size: cover
		background-repeat: no-repeat
		background-image: url(~@/assets/img/banner.jpg)
		width: vw(750px)
		height: vw(532px)
		margin-left: auto
		@media screen and (max-width: 760px)
			border-radius: vwm(8px) vwm(16px) vwm(16px) vwm(8px)
			width: vwm(180px)
			height: vwm(122px)
	&__call
		cursor: pointer
		position: absolute
		width: vw(80px)
		height: vw(80px)
		background-color: #FC4C02
		border-radius: 50%
		bottom: vw(34px)
		right: vw(34px)
		background-image: url(~@/assets/img/phone.svg)
		background-repeat: no-repeat
		background-position: 50%
		background-size: vw(35px)
		@media screen and (max-width: 760px)
			display: none
.swiper-pagination
	.swiper-pagination-bullet
		background-color: #F3F3F3
		opacity: 1
		&-active
			width: 28px
			background-color: #FC4C02
			border-radius: 9px

</style>