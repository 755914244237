<template>
	<div class="catalog">
		<div class="catalog__cards">
			<div class="catalog__card">
				<div class="catalog__card-img catalog__card-img--desk"><img src="@/assets/img/card.jpg" alt="">
					<div class="catalog__card-hover"><img src="@/assets/img/card-2.jpg" alt=""></div>
				</div>
				<div class="swiper swiper--mobile">
					<div class="swiper-wrapper">
						<div class="swiper-slide">
							<div class="catalog__card-img"><img src="@/assets/img/card.jpg" alt=""></div>
						</div>
						<div class="swiper-slide">
							<div class="catalog__card-img"><img src="@/assets/img/card-2.jpg" alt=""></div>
						</div>
					</div>
					<div class="swiper-pagination swiper-pagination--mobile"></div>
				</div>
				<div class="catalog__card-items">
					<div class="catalog__card-price">8 955 590 ₽</div>
					<div class="catalog__card-item"><p>Студия 21,7 м<sup>2</sup></p></div>
					<div class="catalog__card-item catalog__card-item--credit">33 565 ₽ в мес.<a href="">i</a></div>
					<div class="catalog__card-item catalog__card-item--adress">Большая Очаковская 2</div>
					<div class="catalog__card-item catalog__card-item--gray">Готовая отделка</div>
					<div class="catalog__card-item">г. Москва</div>
					<div class="catalog__card-item">Заселение до 2 кв. 2025</div>
					<a class="catalog__card-btn">Подробнее</a>
				</div>
			</div>
			<div class="catalog__card">
				<div class="catalog__card-img catalog__card-img--desk"><img src="@/assets/img/card.jpg" alt="">
					<div class="catalog__card-hover"><img src="@/assets/img/card-2.jpg" alt=""></div>
				</div>
				<div class="swiper swiper--mobile">
					<div class="swiper-wrapper">
						<div class="swiper-slide">
							<div class="catalog__card-img"><img src="@/assets/img/card.jpg" alt=""></div>
						</div>
						<div class="swiper-slide">
							<div class="catalog__card-img"><img src="@/assets/img/card-2.jpg" alt=""></div>
						</div>
					</div>
					<div class="swiper-pagination swiper-pagination--mobile"></div>
				</div>
				<div class="catalog__card-items">
					<div class="catalog__card-price">8 955 590 ₽</div>
					<div class="catalog__card-item"><p>Студия 21,7 м<sup>2</sup></p></div>
					<div class="catalog__card-item catalog__card-item--credit">33 565 ₽ в мес.<a href="">i</a></div>
					<div class="catalog__card-item catalog__card-item--adress">Большая Очаковская 2</div>
					<div class="catalog__card-item catalog__card-item--gray">Готовая отделка</div>
					<div class="catalog__card-item">г. Москва</div>
					<div class="catalog__card-item">Заселение до 2 кв. 2025</div>
					<a class="catalog__card-btn">Подробнее</a>
				</div>
			</div>
			<div class="catalog__card">
				<div class="catalog__card-img catalog__card-img--desk"><img src="@/assets/img/card.jpg" alt="">
					<div class="catalog__card-hover"><img src="@/assets/img/card-2.jpg" alt=""></div>
				</div>
				<div class="swiper swiper--mobile">
					<div class="swiper-wrapper">
						<div class="swiper-slide">
							<div class="catalog__card-img"><img src="@/assets/img/card.jpg" alt=""></div>
						</div>
						<div class="swiper-slide">
							<div class="catalog__card-img"><img src="@/assets/img/card-2.jpg" alt=""></div>
						</div>
					</div>
					<div class="swiper-pagination swiper-pagination--mobile"></div>
				</div>
				<div class="catalog__card-items">
					<div class="catalog__card-price">8 955 590 ₽</div>
					<div class="catalog__card-item"><p>Студия 21,7 м<sup>2</sup></p></div>
					<div class="catalog__card-item catalog__card-item--credit">33 565 ₽ в мес.<a href="">i</a></div>
					<div class="catalog__card-item catalog__card-item--adress">Большая Очаковская 2</div>
					<div class="catalog__card-item catalog__card-item--gray">Готовая отделка</div>
					<div class="catalog__card-item">г. Москва</div>
					<div class="catalog__card-item">Заселение до 2 кв. 2025</div>
					<a class="catalog__card-btn">Подробнее</a>
				</div>
			</div>
			<div class="catalog__card">
				<div class="catalog__card-img catalog__card-img--desk"><img src="@/assets/img/card.jpg" alt="">
					<div class="catalog__card-hover"><img src="@/assets/img/card-2.jpg" alt=""></div>
				</div>
				<div class="swiper swiper--mobile">
					<div class="swiper-wrapper">
						<div class="swiper-slide">
							<div class="catalog__card-img"><img src="@/assets/img/card.jpg" alt=""></div>
						</div>
						<div class="swiper-slide">
							<div class="catalog__card-img"><img src="@/assets/img/card-2.jpg" alt=""></div>
						</div>
					</div>
					<div class="swiper-pagination swiper-pagination--mobile"></div>
				</div>
				<div class="catalog__card-items">
					<div class="catalog__card-price">8 955 590 ₽</div>
					<div class="catalog__card-item"><p>Студия 21,7 м<sup>2</sup></p></div>
					<div class="catalog__card-item catalog__card-item--credit">33 565 ₽ в мес.<a href="">i</a></div>
					<div class="catalog__card-item catalog__card-item--adress">Большая Очаковская 2</div>
					<div class="catalog__card-item catalog__card-item--gray">Готовая отделка</div>
					<div class="catalog__card-item">г. Москва</div>
					<div class="catalog__card-item">Заселение до 2 кв. 2025</div>
					<a class="catalog__card-btn">Подробнее</a>
				</div>
			</div>
			<div class="catalog__card">
				<div class="catalog__card-img catalog__card-img--desk"><img src="@/assets/img/card.jpg" alt="">
					<div class="catalog__card-hover"><img src="@/assets/img/card-2.jpg" alt=""></div>
				</div>
				<div class="swiper swiper--mobile">
					<div class="swiper-wrapper">
						<div class="swiper-slide">
							<div class="catalog__card-img"><img src="@/assets/img/card.jpg" alt=""></div>
						</div>
						<div class="swiper-slide">
							<div class="catalog__card-img"><img src="@/assets/img/card-2.jpg" alt=""></div>
						</div>
					</div>
					<div class="swiper-pagination swiper-pagination--mobile"></div>
				</div>
				<div class="catalog__card-items">
					<div class="catalog__card-price">8 955 590 ₽</div>
					<div class="catalog__card-item"><p>Студия 21,7 м<sup>2</sup></p></div>
					<div class="catalog__card-item catalog__card-item--credit">33 565 ₽ в мес.<a href="">i</a></div>
					<div class="catalog__card-item catalog__card-item--adress">Большая Очаковская 2</div>
					<div class="catalog__card-item catalog__card-item--gray">Готовая отделка</div>
					<div class="catalog__card-item">г. Москва</div>
					<div class="catalog__card-item">Заселение до 2 кв. 2025</div>
					<a class="catalog__card-btn">Подробнее</a>
				</div>
			</div>
			<div class="catalog__card">
				<div class="catalog__card-img catalog__card-img--desk"><img src="@/assets/img/card.jpg" alt="">
					<div class="catalog__card-hover"><img src="@/assets/img/card-2.jpg" alt=""></div>
				</div>
				<div class="swiper swiper--mobile">
					<div class="swiper-wrapper">
						<div class="swiper-slide">
							<div class="catalog__card-img"><img src="@/assets/img/card.jpg" alt=""></div>
						</div>
						<div class="swiper-slide">
							<div class="catalog__card-img"><img src="@/assets/img/card-2.jpg" alt=""></div>
						</div>
					</div>
					<div class="swiper-pagination swiper-pagination--mobile"></div>
				</div>
				<div class="catalog__card-items">
					<div class="catalog__card-price">8 955 590 ₽</div>
					<div class="catalog__card-item"><p>Студия 21,7 м<sup>2</sup></p></div>
					<div class="catalog__card-item catalog__card-item--credit">33 565 ₽ в мес.<a href="">i</a></div>
					<div class="catalog__card-item catalog__card-item--adress">Большая Очаковская 2</div>
					<div class="catalog__card-item catalog__card-item--gray">Готовая отделка</div>
					<div class="catalog__card-item">г. Москва</div>
					<div class="catalog__card-item">Заселение до 2 кв. 2025</div>
					<a class="catalog__card-btn">Подробнее</a>
				</div>
			</div>
			<div class="catalog__card">
				<div class="catalog__card-img catalog__card-img--desk"><img src="@/assets/img/card.jpg" alt="">
					<div class="catalog__card-hover"><img src="@/assets/img/card-2.jpg" alt=""></div>
				</div>
				<div class="swiper swiper--mobile">
					<div class="swiper-wrapper">
						<div class="swiper-slide">
							<div class="catalog__card-img"><img src="@/assets/img/card.jpg" alt=""></div>
						</div>
						<div class="swiper-slide">
							<div class="catalog__card-img"><img src="@/assets/img/card-2.jpg" alt=""></div>
						</div>
					</div>
					<div class="swiper-pagination swiper-pagination--mobile"></div>
				</div>
				<div class="catalog__card-items">
					<div class="catalog__card-price">8 955 590 ₽</div>
					<div class="catalog__card-item"><p>Студия 21,7 м<sup>2</sup></p></div>
					<div class="catalog__card-item catalog__card-item--credit">33 565 ₽ в мес.<a href="">i</a></div>
					<div class="catalog__card-item catalog__card-item--adress">Большая Очаковская 2</div>
					<div class="catalog__card-item catalog__card-item--gray">Готовая отделка</div>
					<div class="catalog__card-item">г. Москва</div>
					<div class="catalog__card-item">Заселение до 2 кв. 2025</div>
					<a class="catalog__card-btn">Подробнее</a>
				</div>
			</div>
			<div class="catalog__card">
				<div class="catalog__card-img catalog__card-img--desk"><img src="@/assets/img/card.jpg" alt="">
					<div class="catalog__card-hover"><img src="@/assets/img/card-2.jpg" alt=""></div>
				</div>
				<div class="swiper swiper--mobile">
					<div class="swiper-wrapper">
						<div class="swiper-slide">
							<div class="catalog__card-img"><img src="@/assets/img/card.jpg" alt=""></div>
						</div>
						<div class="swiper-slide">
							<div class="catalog__card-img"><img src="@/assets/img/card-2.jpg" alt=""></div>
						</div>
					</div>
					<div class="swiper-pagination swiper-pagination--mobile"></div>
				</div>
				<div class="catalog__card-items">
					<div class="catalog__card-price">8 955 590 ₽</div>
					<div class="catalog__card-item"><p>Студия 21,7 м<sup>2</sup></p></div>
					<div class="catalog__card-item catalog__card-item--credit">33 565 ₽ в мес.<a href="">i</a></div>
					<div class="catalog__card-item catalog__card-item--adress">Большая Очаковская 2</div>
					<div class="catalog__card-item catalog__card-item--gray">Готовая отделка</div>
					<div class="catalog__card-item">г. Москва</div>
					<div class="catalog__card-item">Заселение до 2 кв. 2025</div>
					<a class="catalog__card-btn">Подробнее</a>
				</div>
			</div>



			
		</div>
		<a class="catalog__btn">Смотреть все квартиры</a>
	</div>
</template>

<script>
  import Swiper from 'swiper';
  import { Pagination } from 'swiper/modules';
  import 'swiper/swiper-bundle.min.css';
  export default {
	name: "Catalog",
	data() {
		return {
			showModal: false,
			swiper: null,
		}
	},
	mounted() {
		this.swiper = new Swiper('.swiper', {
			loop: true,
			modules: [Pagination],
			pagination: {
				el: '.swiper-pagin',
			},
		});
	},
}
</script>

<style lang="sass">
@import "../sass/functions.sass"
.swiper
	&--mobile
		display: none
		@media screen and (max-width: 760px)
			display: flex
			width: vwm(172px)
			height: vwm(172px)
.catalog
	margin-top: vw(24px)
	@media screen and (max-width: 760px)
		margin-top: vwm(25px)
	&__cards
		display: flex
		flex-wrap: wrap
		justify-content: space-between
		@media screen and (max-width: 760px)
			width: calc( 100% + vwm(30px))
			margin-left: vwm(-15px)
			justify-content: space-evenly
	&__card
		padding: vw(10px) vw(10px) vw(0px) vw(10px)
		margin-bottom: vw(40px)
		@media screen and (max-width: 760px)
			padding: 0
			margin-bottom: vwm(54px)
		&-img
			width: vw(255px)
			height: vw(255px)
			background-color: #F7F7F5
			border-radius: vw(8px)
			position: relative
			@media screen and (max-width: 760px)
				width: vwm(172px)
				height: vwm(172px)
				border-radius: vwm(8px)
			&--desk
				@media screen and (max-width: 760px)
					display: none
			img
				width: 88%
				height: 88%
				display: flex
				margin: auto
		&-hover
			position: absolute
			top: 0
			left: 0
			opacity: 0
			width: vw(255px)
			height: vw(255px)
			background-color: #F7F7F5
			border-radius: vw(8px)
			transition: all 0.5s
			&:hover
				opacity: 1
			@media screen and (max-width: 760px)
				display: none
			img
				width: 88%
				height: 88%
				display: flex
				margin: auto

		&-items
			margin-top: vw(27px)
			padding-left: vw(6px)
			@media screen and (max-width: 760px)
				margin-top: vwm(20px)
				padding-left: vwm(6px)
		&-price
			color: #1C1F22
			font-family: "SB Sans Display"
			font-size: vw(18px)
			font-weight: 750
			line-height: vw(24px)
			@media screen and (max-width: 760px)
				font-size: vwm(16px)
		&-item
			color: #1C1F22
			font-family: "SB Sans Text"
			font-size: vw(12px)
			font-weight: 400
			line-height: vw(16px)
			letter-spacing: vw(-0.24px)
			margin-top: vw(8px)
			@media screen and (max-width: 760px)
				font-size: vwm(12px)
				line-height: vwm(14px)
				margin-top: vwm(12px)
			&--credit
				border-radius: vw(4px)
				background: #A8FDE0
				display: table
				padding: vw(5px)
				margin-top: vw(10px)
				position: relative
				@media screen and (max-width: 760px)
					border-radius: vwm(4px)
					margin-top: vwm(12px)
					font-size: vwm(10px)
					padding: vwm(3px) vwm(10px) vwm(3px) vwm(4px)

				a
					cursor: pointer
					display: flex
					align-items: center
					justify-content: center
					position: absolute
					top: vw(-3px)
					width: vw(10px)
					height: vw(10px)
					background-color: #D9D9D9
					border-radius: 50%
					right: 0
					color: #000
					font-family: SB Sans Text
					font-size: vw(8px)
					line-height: vw(11px)
					text-decoration: none
					@media screen and (max-width: 760px)
						font-size: vwm(8px)
						width: vwm(10px)
						height: vwm(10px)
						top: vwm(-4px)

			&--adress
				display: flex
				align-items: center
				@media screen and (max-width: 760px)
					margin-top: vwm(10px)
				&::before
					content: ''
					background-size: contain
					background-repeat: no-repeat
					background-image: url(~@/assets/img/home.svg)
					width: vw(16px)
					height: vw(16px)
					margin-right: vw(6px)
					margin-left: vw(2px)
					@media screen and (max-width: 760px)
						width: vwm(14px)
						height: vwm(14px)
						margin-right: vwm(6px)
						margin-left: vwm(2px)
			&--gray
				margin-top: vw(6px)
				color: #8C959C
				@media screen and (max-width: 760px)
					margin-top: vwm(9px)
			&:nth-child(6)
				@media screen and (max-width: 760px)
					margin-top: vwm(7px) !important
			&:nth-child(7)
				@media screen and (max-width: 760px)
					margin-top: vwm(7px) !important
			
		&-btn
			display: flex
			width: vw(120px)
			height: vw(36px)
			padding: vw(8px) vw(16px)
			justify-content: center
			align-items: center
			gap: vw(4px)
			border-radius: vw(32px)
			background: #FC4C02
			color: #FFF
			text-align: center
			font-family: "SB Sans Text"
			font-size: vw(14px)
			font-weight: 400
			line-height: vw(20px)
			letter-spacing: vw(-0.28px)
			margin-top: vw(20px)
			cursor: pointer
			transition: all 0.5s
			@media screen and (max-width: 760px)
				width: vwm(120px)
				height: vwm(36px)
				border-radius: vwm(1000px)
				margin-top: vwm(17px)
				font-size: vwm(14px)
			&:hover
				background: #E44502
	&__btn
		display: flex
		width: vw(1130px)
		height: vw(48px)
		padding: vw(8px) vw(16px)
		justify-content: center
		align-items: center
		gap: vw(4px)
		flex-shrink: 0
		border-radius: vw(32px)
		background: #F2F5F7
		color: #FC4C02
		text-align: center
		font-size: vw(14px)
		font-style: normal
		font-weight: 600
		line-height: vw(20px)
		letter-spacing: vw(-0.28px)
		cursor: pointer
		transition: all 0.5s
		@media screen and (max-width: 760px)
			width: 100%
			padding: vwm(8px) vwm(16px)
			height: vwm(32px)
			font-size: vwm(12.284px)
			margin-top: vwm(-25px)
			border-radius: vwm(1000px)
		&:hover
			background: #E4EBF0
.swiper-pagination
	&--mobile
		text-align: end
		margin-top: 10px
		.swiper-pagination-bullet
			background-color: #BEC9D1
			opacity: 1
			width: 5px
			height: 5px
			margin: -11px var(--swiper-pagination-bullet-horizontal-gap, 10px -5px -5px) !important
			&:first-child
			margin-right: 3px
			&:last-child
				margin-right: 18px
			&-active
				width: 5px
				height: 5px
				background-color: #677178
								
			
</style>