<template>
	<div class="banner">
		<div class="banner__text">
			<div class="banner__text-title">Квартиры с готовой<br>отделкой</div>
			<div class="banner__text-subtitle">Пропускаем стадию ремонта,<br>распаковываем коробки и принимаем гостей</div>
		</div>
		<div class="banner__gradietn"></div>
	</div>
</template>

<script>
  export default {
	name: "Banner",
  }
</script>

<style lang="sass" scoped>
@import "../sass/functions.sass"
.banner
	margin-top: vw(48px)
	display: flex
	border-radius: vw(24px)
	background-size: vw(1150px)
	background-repeat: no-repeat
	background-image: url(~@/assets/img/banner-3.jpg)
	width: vw(1130px)
	height: vw(170px)
	background-position: 50% 61%
	position: relative
	margin-bottom: vw(26px)
	@media screen and (max-width: 760px)
		border-radius: vwm(16px)
		width: 100%
		height: vwm(120px)
		background-size: vwm(400px)
		background-position: 86% 54.5%
		margin-top: vwm(32px)
	&__text
		z-index: 100
		padding-top: vw(24px)
		padding-left: vw(72px)
		color: #FFF
		font-family: "SB Sans Display"
		@media screen and (max-width: 760px)
			padding-top: vwm(20px)
			padding-left: vwm(20px)
		&-title
			font-size: vw(32px)
			font-style: normal
			font-weight: 500
			line-height: vw(35.2px)
			letter-spacing: vw(-0.64px)
			@media screen and (max-width: 760px)
				font-size: vwm(16px)
				line-height: vwm(18px)
		&-subtitle
			margin-top: vw(12px)
			font-size: vw(18px)
			font-style: normal
			font-weight: 400
			line-height: vw(20px)
			@media screen and (max-width: 760px)
				margin-top: vwm(6px)
				font-size: vwm(12px)
				line-height: vwm(14px)
	&__gradietn
		z-index: 10
		position: absolute
		width: vw(805px)
		height: vw(170px)
		background: linear-gradient(90deg, rgba(0, 0, 0, 0.70) 0.11%, rgba(0, 0, 0, 0.48) 49.71%, rgba(0, 0, 0, 0.00) 99.78%)
		top: 0
		border-radius: vw(24px) 0 0 vw(24px)
		@media screen and (max-width: 760px)
			border-radius: vwm(16px)
			width: 100%
			height: vwm(120px)
</style>