<template>
	<div class="banner">
		<div class="banner__content">
			<div class="banner__text">
				<div class="banner__text-title">Выгодная ипотека</div>
				<a class="banner__text-btn">Смотреть условия</a>
			</div>
			<div class="banner__img"></div>
		</div>
	</div>
</template>

<script>
  export default {
	name: "Banner",
  }
</script>

<style lang="sass" scoped>
@import "../sass/functions.sass"
.banner
	width: vw(1130px)
	height: vw(296px)
	border-radius:  vw(24px)
	background: #FAFAFA
	margin-top: vw(50px)
	@media screen and (max-width: 760px)
		border-radius: vwm(16px)
		width: vwm(343px)
		height: vwm(122px)
		margin-top: vwm(32px)
	&__content
		display: flex
	&__text
		padding-top: vw(48px)
		padding-left: vw(72px)
		@media screen and (max-width: 760px)
			padding-top: vwm(22px)
			padding-left: vwm(15px)
		&-title
			color: #000
			font-family: "Graphik LC"
			font-size: vw(32px)
			font-style: normal
			font-weight: 600
			line-height: vw(38.4px)
			letter-spacing: vw(-0.64px)
			@media screen and (max-width: 760px)
				font-size: vwm(13px)
		&-btn
			display: flex
			width: vw(275px)
			height: vw(36px)
			padding: vw(8px) vw(16px)
			justify-content: center
			align-items: center
			font-family: "SB Sans Text"
			gap: vw(4px)
			border-radius: vw(32px)
			background: #FC4C02
			color: #FFF
			text-align: center
			font-size: vw(14px)
			font-weight: 400
			line-height: vw(20px)
			letter-spacing: vw(0.3px)
			margin-top: vw(26px)
			transition: all 0.5s
			cursor: pointer
			@media screen and (max-width: 760px)
				width: vwm(113px)
				height: vwm(15px)
				font-size: vwm(6px)
				padding: vwm(8px) vwm(16px)
				margin-top: vwm(15px)
				border-radius: vwm(1000px)
			&:hover
				background: #E44502
	&__img
		display: flex
		border-radius: vw(24px)
		background-size: vw(983px)
		background-repeat: no-repeat
		background-image: url(~@/assets/img/banner-4.jpg)
		width: vw(685px)
		height: vw(296px)
		margin-left: auto
		background-position: 32% 45%
		@media screen and (max-width: 760px)
			height: vwm(122px)
			width: vwm(173px)
			background-size: vwm(410px)
			background-position: 17% 46%
			border-radius: vwm(10px) vwm(16px) vwm(16px) vwm(10px)
</style>