<template>
	<div class="banner">
		<div class="banner__text">
			<div class="banner__text-title">Квартиры с французскими<br>балконами</div>
			<div class="banner__text-subtitle">Благодаря панорамному остеклению<br>здесь всегда много света</div>
		</div>
		<div class="banner__gradietn"></div>
	</div>
</template>

<script>
  export default {
	name: "Banner",
  }
</script>

<style lang="sass" scoped>
@import "../sass/functions.sass"
.banner
	display: flex
	border-radius: vw(24px)
	background-size: vw(1440px)
	background-repeat: no-repeat
	background-image: url(~@/assets/img/banner-2.jpg)
	width: vw(1130px)
	height: vw(170px)
	background-position: 89% 58.5%
	position: relative
	@media screen and (max-width: 760px)
		border-radius: vwm(16px)
		width: 100%
		height: vwm(120px)
		background-size: vwm(440px)
		background-position: 90% 65.5%

	&__text
		z-index: 100
		padding-top: vw(24px)
		padding-left: vw(72px)
		color: #FFF
		font-family: "SB Sans Display"
		@media screen and (max-width: 760px)
			padding-top: vwm(18px)
			padding-left: vwm(20px)
		&-title
			font-size: vw(32px)
			font-style: normal
			font-weight: 400
			line-height: vw(35px)
			letter-spacing: vw(-0.64px)
			@media screen and (max-width: 760px)
				font-size: vwm(16px)
				line-height: vwm(18px)
		&-subtitle
			margin-top: vw(12px)
			font-size: vw(18px)
			line-height: vw(20px)
			@media screen and (max-width: 760px)
				margin-top: vwm(6px)
				font-size: vwm(12px)
				line-height: normal

	&__gradietn
		z-index: 10
		position: absolute
		width: vw(805px)
		height: vw(170px)
		background: linear-gradient(90deg, rgba(0, 0, 0, 0.70) 0.28%, rgba(0, 0, 0, 0.00) 87.5%)
		top: 0
		border-radius: vw(24px) 0 0 vw(24px)
		@media screen and (max-width: 760px)
			border-radius: vwm(16px)
			width: 100%
			height: vwm(120px)
</style>