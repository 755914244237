<template>
	<div class="header">
		<div class="container">
			<div class="header__content">
				<a class="header__menu"><img src="../assets/img/Burger.svg" alt="" @click="showModal = true"></a>
				<div class="header__logo"><img src="../assets/img/logo.svg" alt=""></div>
				<div class="header__mobile"><img src="../assets/img/megamarket.svg" alt=""></div>
				<a class="header__btn header__btn--hit">Мега Хит</a>
				<a class="header__btn header__btn--carrot">Супермаркет</a>
				<a class="header__btn header__btn--fire">Акции</a>
			</div>
			<div class="header__modal" v-if="showModal == true">
				<div class="header__modal-top">
					<div class="header__modal-close" @click="showModal = false"></div>
					<div class="header__modal-title">Меню</div>
				</div>
				<div class="header__modal-menu">
					<a class="header__modal-btn header__modal-btn--hit">Мега Хит</a>
					<a class="header__modal-btn header__modal-btn--carrot">Супермаркет</a>
					<a class="header__modal-btn header__modal-btn--fire">Акции</a>
				</div>
				<a class="header__modal-call" @click="openCall">Заказать звонок</a>
			</div>
		</div>
		<div class="hero__modal" @click="modalClick" v-if="showModalCall == true">
			<div class="hero__iframe-wrapper">
				<iframe src="https://www.pik.ru/callback?source=megamarket" class="hero__iframe" frameborder="0"></iframe>
				<div class="hero__close" @click="showModalCall = false"></div>
			</div>
		</div>
	</div>
</template>

<script>
  export default {
	name: "Header",
	data() {
		return {
			showModal: false,
			showModalCall: false,
		}
	},
	methods: {
		openCall() {
			this.showModal = false;
			this.showModalCall = true;
		},
		modalClick(event) {
		if (event.target.classList.contains('hero__modal') && this.showModalCall === true) {
			this.showModalCall = false;
		}},
	},
  }
</script>

<style lang="sass">
@import "../sass/functions.sass"
.hero
	&__modal
		position: fixed
		top: 0
		left: 0
		z-index: 200
		width: 100%
		height: 100%
		background: rgba(0, 0, 0, 0.40)
		display: flex
		align-items: center
		justify-content: center
	&__iframe
		&-wrapper
			width: 521px
			height: 600px
			position: relative
			@media screen and (max-width: 760px)
				width: 343px
				height: 663px
			iframe
				width: 100%
				height: 100%
	&__close
		cursor: pointer
		position: absolute
		top: vw(20px)
		right: vw(20px)
		background-size: contain
		background-repeat: no-repeat
		background-image: url(~@/assets/img/close1.svg)
		width: vw(16px)
		height: vw(16px)
		@media screen and (max-width: 760px)
			width: vwm(12px)
			height: vwm(12px)
			top: vwm(20px)
			right: vwm(20px)
.header
	padding: vw(15px) 0
	@media screen and (max-width: 760px)
		padding: vwm(11px) 0
	&__content
		display: flex
		align-items: center
	&__menu
		display: none
		@media screen and (max-width: 760px)
			display: flex
			align-items: center
			cursor: pointer
	&__logo
		margin-top: vw(2px)
		margin-right: vw(24px)
		@media screen and (max-width: 760px)
			display: none
		img
			width: vw(90px)
			height: vw(48px)
			@media screen and (max-width: 760px)
				display: none
	&__mobile
		display: none
		@media screen and (max-width: 760px)
			margin-top: vwm(2px)
			display: flex
			margin-left: vwm(8px)
			img
				width: vwm(117px)
				height: vwm(17px)
		
	&__btn
		display: flex
		padding: vw(12px) vw(16px) vw(12px) vw(14px)
		justify-content: center
		align-items: center
		gap: vw(6px)
		border-radius: vw(16px)
		border: vw(1px) solid #E2E6E9
		background: #F2F5F7
		color: #1C1F22
		font-family: "SB Sans Text"
		font-size: vw(14px)
		font-style: normal
		font-weight: 400
		line-height: vw(20px)
		letter-spacing: vw(-0.28px)
		margin-right: vw(9px)
		cursor: pointer
		transition: all 1s
		@media screen and (max-width: 760px)
			display: none
		&:hover
			opacity: 0.8
		&::before
			content: ''
			background-size: contain
			background-repeat: no-repeat
		&--hit
			&::before
				background-image: url(~@/assets/img/sale.png)
				width: vw(24px)
				height: vw(24px)
		&--carrot
			&::before
				background-image: url(~@/assets/img/Carrot.svg)
				width: vw(24px)
				height: vw(24px)
		&--fire
			&::before
				background-image: url(~@/assets/img/Fire.svg)
				width: vw(24px)
				height: vw(24px)
	&__modal
		display: none
		@media screen and (max-width: 760px)
			display: block
			top: 0
			left: 0
			position: fixed
			z-index: 200
			width: 100%
			height: 100%
			background-color: #FFF
		&-top
			display: flex
			align-items: center
			justify-content: center
			position: relative
			padding: vwm(8px) vwm(14px)
			border-bottom: 1px solid rgba(212, 218, 223, 0.5)
		&-title
			display: flex
			justify-content: center
			color: #1C1F22
			text-align: center
			font-family: SB Sans Display
			font-size: vwm(18px)
			font-style: normal
			font-weight: 750
			line-height: vwm(24px)
		&-close
			cursor: pointer
			position: absolute
			left: vwm(12px)
			background-size: contain
			background-repeat: no-repeat
			background-image: url(~@/assets/img/Cross.svg)
			width: vwm(24px)
			height: vwm(24px)
		&-menu
			display: inline-flex
			align-items: center
			margin-top: vwm(12px)
			overflow-x: auto
			width: calc( 100% + vwm(40px) )
		&-btn
			display: inline-flex
			padding: vwm(12px) vwm(16px) vwm(12px) vwm(14px)
			border-radius: vwm(16px)
			border: vwm(1px) solid #E2E6E9
			background: #F2F5F7
			color: #1C1F22
			font-family: "SB Sans Text"
			font-size: vwm(14px)
			font-style: normal
			font-weight: 400
			line-height: vwm(20px)
			letter-spacing: vwm(-0.28px)
			margin-left: vwm(12px)
			cursor: pointer
			transition: all 1s
			height: vwm(48px)
			&::before
				content: ''
				background-size: contain
				background-repeat: no-repeat
			&--hit
				&::before
					background-image: url(~@/assets/img/sale.png)
					width: vwm(24px)
					height: vwm(24px)
					margin-right: vwm(3px)
			&--carrot
				&::before
					background-image: url(~@/assets/img/Carrot.svg)
					width: vwm(24px)
					height: vwm(24px)
					margin-right: vwm(3px)
			&--fire
				&::before
					background-image: url(~@/assets/img/Fire.svg)
					width: vwm(22px)
					height: vwm(22px)
					margin-right: vwm(3px)
		&-call
			cursor: pointer
			display: flex
			width: vwm(343px)
			height: vwm(36px)
			padding: vwm(8px) vwm(16px)
			justify-content: center
			align-items: center
			gap: vwm(4px)
			border-radius: vwm(1000px)
			background: #FC4C02
			margin: auto
			margin-top: vwm(29px)
			color: #FFF
			font-family: SB Sans Text
			font-size: vwm(14px)
			line-height: vwm(20px)
			letter-spacing: vwm(-0.28px)


</style>