<template>
	<div class="container">
		<div class="footter">
			<div class="footter__top">
				<div class="footter__top-logo">
					<a href=""><img src="../assets/img/megamarket.svg" alt=""></a>
					<a href=""><img src="../assets/img/Pik-small.svg" alt=""></a>
				</div>
				<div class="footter__top-links">
					<a href=""><img src="../assets/img/youtube.svg" alt=""></a>
					<a href=""><img src="../assets/img/vk.svg" alt=""></a>
				</div>

			</div>
			<div class="footter__text">
				<p>Все права на публикуемые на сайте материалы принадлежат ПАО «ПИК-специализированный застройщик» © 2000 — 2023.<br>
Пользователь уведомлен, что любые материалы, размещенные на сайте, являются объектами интеллектуальной собственности ПАО «ПИК-специализированный застройщик» (правообладателя). Пользователь не вправе без предварительного письменного разрешения правообладателя осуществлять какие-либо действия с объектами интеллектуальной собственности, в противном случае, правообладатель оставляет за собой право на взыскание штрафов, предусмотренных законодательством РФ, а также на обращение в компетентные органы за защитой своих прав и законных интересов.<br>
Любая информация, представленная на данном сайте, носит исключительно информационный характер и ни при каких условиях не является публичной офертой, определяемой положениями статьи 437 ГК РФ.
Раскрытие информации ПАО «ПИК-специализированный застройщик» также доступно в сети Интернет на странице информационного агентства, аккредитованного ЦБ РФ на раскрытие информации — <a href="https://e-disclosure.ru/portal/company.aspx?id=44" target="_blank">ООО «Интерфакс-ЦРКИ»</a>. Раскрытие информации ООО «ПИК-Корпорация» (подконтрольная организация ПАО «ПИК-специализированный застройщик») доступно в сети Интернет на странице информационного агентства, аккредитованного ЦБ РФ на раскрытие информации — <a href="https://e-disclosure.ru/portal/company.aspx?id=44" target="_blank">ООО «Интерфакс-ЦРКИ»</a>. ПАО Сбербанк. Генеральная лицензия Банка России на осуществление банковких операций № 1481 от 11.08.2015 г.</p>
			</div>
			<div class="footter__mobile">
				<p>Партнер Парнер ПАО «ПИК-специализированный застройщик». ОГРН 1027739137084. г. Москва, 123242, ул. Баррикадная, д. 19, стр. 1. Проектные декларации на наш.дом.рф. Любая информация, представленная на данном сайте, носит исключительно информационный характер и ни при каких условиях не является публичной офертой, определяемой положениями статьи 437 ГК РФ
<br><br>
Реклама | ПАО «ПИК-специализированный застройщик»</p>
			</div>
		</div>
	</div>
</template>

<script>
  export default {
	name: "Header",
  }
</script>

<style lang="sass">
@import "../sass/functions.sass"
.footter
	margin-top: vw(53px)
	@media screen and (max-width: 760px)
		margin-top: vwm(53px)
	&__top
		display: flex
		align-items: center
		@media screen and (max-width: 760px)
			display: block
		&-logo
			display: flex
			a
				cursor: pointer
				&:first-child
					display: flex
					align-items: flex-end
					padding-right: vw(11px)
					border-right: vw(1px) solid #CACACA
					@media screen and (max-width: 760px)
						padding-right: vwm(11px)
					img
						width: vw(178px)
						height: vw(25px)
						@media screen and (max-width: 760px)
							width: vwm(175px)
							height: vwm(23px)

				&:last-child
					margin-left: vw(12px)
					@media screen and (max-width: 760px)
						margin-left: vwm(-45px)
					img
						width: vw(54px)
						height: vw(25px)
						@media screen and (max-width: 760px)
							width: vwm(175px)
							height: vwm(20px)

		&-links
			display: flex
			align-items: flex-end
			margin-left: auto
			@media screen and (max-width: 760px)
				margin-top: vwm(15px)
				align-items: center
			a
				cursor: pointer
				&:first-child
					margin-right: vw(7px)
					@media screen and (max-width: 760px)
						margin-right: vwm(12px)
					img
						width: vw(30px)
						height: vw(28px)
						@media screen and (max-width: 760px)
							width: vwm(29px)
							height: vwm(29px)
				&:last-child
					img
						width: vw(32px)
						height: vw(27px)
						@media screen and (max-width: 760px)
							width: vwm(25px)
							height: vwm(25px)
					
	&__text
		margin-top: vw(25px)
		@media screen and (max-width: 760px)
			display: none
		p
			color: #000
			font-size: vw(14px)
			font-family: Graphik LC
			font-weight: 400
			line-height: vw(20px)
			letter-spacing: vw(-0.28px)
			opacity: 0.5
			a
				cursor: pointer
				color: #000
	&__mobile
		display: none
		p
			color: #000
			font-size: vwm(10px)
			font-family: Graphik LC
			font-weight: 400
			line-height: vwm(14px)
			letter-spacing: vwm(-0.2px)
			opacity: 0.5
		@media screen and (max-width: 760px)
			display: block
			margin-top: vwm(16px)
</style>