<template>
	<div class="container">
		<div class="nav">
			<a class="nav__item">Все квартиры</a>
			<a class="nav__item">Дом построен, есть отделка</a>
			<a class="nav__item">Две ванные комнаты</a>
			<a class="nav__item">Площадь 90+ м2</a>
			<a class="nav__item">Французский балкон</a>
			<a class="nav__item">Большие игровые площадки</a>
		</div>
	</div>
</template>

<script>
  export default {
	name: "Navigation",
  }
</script>

<style lang="sass">
@import "../sass/functions.sass"
.nav
	margin-top: vw(35px)
	padding: vw(8px) vw(6px)
	display: flex
	border-radius: vw(24px)
	background: #F2F5F7
	@media screen and (max-width: 760px)
		padding: vwm(12px) vwm(8px) vwm(12px) vwm(16px)
		overflow-x: auto
		width: calc(100% + vwm(30px))
		margin-top: vwm(16px)
		margin-left: vwm(-15px)
		border-radius: 0
	&__item
		display: flex
		padding: vw(12px) vw(16px)
		align-items: center
		gap: vw(8px)
		color: #8C959C
		font-family: "SB Sans Text"
		font-size: vw(14px)
		font-style: normal
		font-weight: 600
		line-height: vw(18px)
		border-radius: vw(16px)
		margin-right: vw(9px)
		cursor: pointer
		transition: all 0.5s
		@media screen and (max-width: 760px)
			font-size: vwm(12px)
			padding: vwm(13px) vwm(10px) vwm(12px) vwm(10px)
			line-height: normal
			border-radius: vwm(16px)
			white-space: nowrap
			margin-right: vwm(9px)

		&:hover
			color: #1C1F22
			background: #FFF

</style>