<template>
	<div class="filter">
		<div class="filer__item">
			<div class="filter__label">Цена, руб</div>
			<div ref="slider"></div>
			<div class="filter__slider-content">
				<div class="filter__slider-field">
					<div class="filter__slider-prefix">от</div>
					<input type="text" class="filter__slider-label" v-model="rangeStart" readonly>
					<div class="filter__slider-sufix">Р</div>
				</div>
				<div class="filter__slider-field">
					<div class="filter__slider-prefix">от</div>
					<input type="text" class="filter__slider-label" v-model="rangeEnd" readonly>
					<div class="filter__slider-sufix">Р</div>
				</div>
			</div>
		</div>
		<select class="filter__select">
			<option value="" selected>Москва и Московская область <span class="filter__select-arrow"></span></option>
			<option value="1">Москва и Московская область</option>
			<option value="2">Санкт-Петербург и ЛО</option>
			<option value="3">Екатеринбург</option>
			<option value="4">Казань</option>
			<option value="5">Нижний Новгород</option>
			<option value="3">Владивосток</option>
		</select>

		<div class="filter__btn">Очистить фильтры</div>
	</div>
</template>

<script>
	import noUiSlider from 'nouislider';
	import 'nouislider/dist/nouislider.css';
	export default {
		name: "Filters",
		data() {
			return {
				slider: null,
				rangeStart: 4000000,
				rangeEnd: 8000000,
			}
		},
		mounted() {
			this.slider = noUiSlider.create(this.$refs.slider, {
				start: [this.rangeStart, this.rangeEnd],
				connect: true,
				range: {
					'min': Math.round(this.rangeStart),
					'max': Math.round(this.rangeEnd)
				}
			});
			this.slider.on('update', (values) => {
				this.rangeStart = Math.round(values[0]);
				this.rangeEnd = Math.round(values[1]);
			});
		}
	}
</script>

<style lang="sass">
@import "../sass/functions.sass"
.filter
	width: vw(278px)
	max-width: vw(278px)
	@media screen and (max-width: 760px)
			display: none !important
	&__label
		color: #1C1F22
		font-family: "SB Sans Text"
		font-size: vw(14px)
		font-style: normal
		font-weight: 600
		line-height: vw(20px)
		letter-spacing: vw(-0.28px)
		margin-bottom: vw(25px)
	&__slider
		&-label
			color: #1C1F22
			font-size: vw(14px)
			font-weight: 400
			line-height: vw(18px)
		&-field
			position: relative
			width: vw(135px)
			&:last-child
				margin-left: vw(8px)
		&-prefix
			position: absolute
			left: vw(8px)
			top: vw(8px)
			color: #8C959C
			font-size: vw(12px)
			font-style: normal
			font-weight: 400
			line-height: vw(16px)
		&-sufix
			position: absolute
			right: vw(8px)
			top: vw(8px)
			color: #8C959C
			font-size: vw(12px)
			font-style: normal
			font-weight: 400
			line-height: vw(16px)
		&-content
			display: flex
			width: 100%
			margin-top: vw(25px)
			input
				height: vw(33px)
				border-radius: vw(8px)
				border: vw(1px) solid #D4DADF
				font-family: "SB Sans Text"
				font-style: normal
				font-weight: 400
				line-height: vw(18px)
				outline: none
				cursor: pointer
				width: vw(135px)
				padding-left: vw(40px)

	&__select
		display: flex
		padding: vw(13px) vw(12px) vw(13px) vw(16px)
		align-items: center
		gap: vw(16px)
		border-radius: vw(16px)
		border: vw(1px) solid #E2E6E9
		background: #F2F5F7
		color: #1C1F22
		font-family: "SB Sans Text"
		font-size: vw(14px)
		font-style: normal
		font-weight: 400
		line-height: vw(20px)
		letter-spacing: vw(-0.28px)
		margin-top: vw(32px)
		outline: none
		cursor: pointer
		position: relative
		-webkit-appearance: none
		span
			display: flex
			position: absolute
			background-repeat: no-repeat
			background-image: url(~@/assets/img/arrow.svg)
			background-size: vw(24px)

	&__btn
		display: flex
		height: vw(36px)
		padding: vw(8px) vw(16px)
		justify-content: center
		align-items: center
		gap: vw(4px)
		border-radius: vw(1000px)
		background: #F2F5F7
		color: #677178
		text-align: center
		font-family: "SB Sans Text"
		font-size: vw(12px)
		font-style: normal
		font-weight: 600
		line-height: vw(16px)
		letter-spacing: vw(-0.24px)
		margin-top: vw(32px)
		cursor: pointer
		transition: all 0.5s
		&:hover
			border: vw(1px) solid #5D3A8E
			color: #5D3A8E
// slider style
.noUi-handle
	width: vw(28px) !important
	height: vw(28px) !important
	background-color:  #FFF
	filter: drop-shadow(vw(0px) vw(2px) vw(4px) rgba(8, 9, 10, 0.06))
	border-radius: 50%
	box-shadow: none
	right: vw(-15px) !important
	top: vw(-12px) !important
	cursor: pointer
	transition: all 0.5s
	&:hover
		width: vw(30px) !important
		height: vw(30px) !important
		top: vw(-13px) !important
.noUi-connect
	width: 100%
	height: vw(2px)
	background-color: #9B38DC
.noUi-target
	width: vw(270px)
	height: vw(2px)
	margin-top: vw(-1px)
	background-color: #D4DADF
	border: none
	box-shadow: none
.noUi-handle:before, .noUi-handle:after
	display: none !important
</style>